import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/card"
import AnchorLink from "../components/anchorLink"
import PostList from "../components/postList"

import AstronautIllustration from "../assets/illustrations/astronaut.svg"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import VideocamIcon from "@mui/icons-material/Videocam"
import DescriptionIcon from "@mui/icons-material/Description"
import MicIcon from "@mui/icons-material/Mic"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const HeroSection = () => (
    <div className="lg:flex items-center py-16 gap-24">
        <div className="lg:flex-grow">
            <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
                All resources to make you a better designer.
            </h1>
            <p className="text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">
                Easiest way to Keep up with the latest design trends and resources you
                care about. Learn UI & UX design from expertly curated content and
                resources around the world.
            </p>
            <AnchorLink isButton to="#resources">
                <span>Scroll down</span>
                <ExpandMoreIcon />
            </AnchorLink>
        </div>
        <div className="md:flex-shrink-0 mt-12 md:mt-0">
            <AstronautIllustration className="mx-auto" />
        </div>
    </div>
)

const resources = [
    {
        name: "Books",
        Icon: MenuBookIcon,
        url: "/resources/books",
    },
    {
        name: "Videos",
        Icon: VideocamIcon,
        url: "/resources/videos",
    },
    {
        name: "Articles",
        Icon: DescriptionIcon,
        url: "/resources/articles",
    },
    {
        name: "Podcasts",
        Icon: MicIcon,
        url: "/resources/podcasts",
    },
    {
        name: "Events",
        Icon: CardGiftcardIcon,
        url: "/resources/events",
    },
]

function ResourcesSection() {
    return (
        <section className="py-10">
            <h2
                id="resources"
                className="text-3xl sm:text-5xl lg:text-6xl leading-none font-bold text-gray-900 tracking-tight mb-8"
            >
                Resources
            </h2>
            <p className="max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10 mb-10">
                Up-to-date collection of top articles, books, podcasts, videos and more to
                help you with your design journey.
            </p>
            <div className="flex flex-wrap gap-12 mb-10">
                {resources.map(resource => (
                    <AnchorLink
                        key={resource.name}
                        to={resource.url}
                        className="flex-grow flex-shrink-0"
                    >
                        <Card className="text-center whitespace-nowrap p-6">
                            <resource.Icon
                                fontSize=""
                                className="mb-4 text-5xl text-blue-primary"
                            />
                            <div className="font-bold text-gray-700">{resource.name}</div>
                        </Card>
                    </AnchorLink>
                ))}
            </div>
            <div>
                <AnchorLink
                    to="/resources"
                    className="font-semibold inline-flex items-center text-blue-primary"
                >
                    <span>View all resources</span>
                    <ArrowForwardIcon fontSize="" className="text-lg text-red-500 ml-2" />
                </AnchorLink>
            </div>
        </section>
    )
}

function RecentPosts({ posts }) {
    return (
        <section className="py-10">
            <h2
                id="resources"
                className="text-3xl sm:text-5xl lg:text-6xl leading-none font-bold text-gray-900 tracking-tight mb-8"
            >
                Latest articles
            </h2>
            <div>
                <PostList posts={posts} />
            </div>
        </section>
    )
}

const HomePage = ({ data, location }) => {
    const posts = data.allMarkdownRemark.nodes

    return (
        <Layout location={location}>
            <Seo title="UX Choice | Best hand-picked resources for UI, UX and product designers." description="UX Choice is a curated library of best UI and UX design resources. Find the best books, inspiration, quotes, articles, videos, tutorial and more for UI, UX, and product designers." />
            <main>
                <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
                    <HeroSection />
                    <ResourcesSection />
                    <RecentPosts posts={posts} />
                </div>
            </main>
        </Layout>
    )
}

export default HomePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 6
      filter: { frontmatter: { categories: { ne: "quotes" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
